import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart, BarChart, CandlestickChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    MarkAreaComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import { green, grey, red } from '@mui/material/colors';
import { Height } from '../util/ScreenHeight'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

echarts.use(
    [VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, TitleComponent, TooltipComponent, GridComponent, LineChart, CandlestickChart, CanvasRenderer, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent, MarkAreaComponent, BarChart]
);


const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        // marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        // width: `calc(100% + ${6 + (theme.spacing(1))}px)`
    }
}));

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
    };
}


export default function BigDetail(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [option, setOption] = React.useState(null)
    const [option2, setOption2] = React.useState(null);

    const [result, setResult] = React.useState(null);

    const [open, setOpen] = React.useState(false);

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [alignment, setAlignment] = React.useState('OLS');

    const handleChange = (event, newAlignment) => {
        setAlignment(newAlignment);
    };

    const [value, setValue] = React.useState(0);

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
        if (newValue === 0) setAlignment("OLS")
        else if (newValue === 1) setAlignment("DecisionTree")
        else if (newValue === 2) setAlignment("LASSO")
        else if (newValue === 3) setAlignment("SVR")
        else if (newValue === 4) setAlignment("Ridge")
        else if (newValue === 5) setAlignment("ElasticNet")
        else if (newValue === 6) setAlignment("GaussianProcessRegression")
        else if (newValue === 7) setAlignment("RBFNetwork")
        else if (newValue === 8) setAlignment("RandomForest")
        else if (newValue === 9) setAlignment("LSTM")
        else if (newValue === 10) setAlignment("CNN")
    };

    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
    };

    useEffect(() => {
        if (props.open) setOpen(true)
        else setOpen(false)
    }, [props.open]);

    function genoption1() {

        let k = {
            animation: false,
            tooltip: {
                show: true
            },
            grid: [{
                top: 10,
                left: 0,
                right: 20,
                bottom: 0,
                containLabel: true
            }],
            axisPointer: {
                show: true
            },
            xAxis: [{
                type: 'category',
                boundaryGap: false,
                data: result.xy_prices.map(row => row.x),
            }],
            yAxis: [{
                scale: true,
                type: 'value',
                position: 'left',
                show: true,
            }],
            dataZoom: {
                type: 'inside',
                xAxisIndex: [0, 1],
                start: 30,
                end: 100
            }

            // series: [

            // ]
        };

        let a = []
        result.av.map(row => {
            a.push({
                name: row.n,
                type: 'line',
                data: [[result.big_price.ymd, result.big_price.price], ["+2D", row.d], ["+4D", row.d1], ["+6D", row.d2]],
                smooth: false,
                showSymbol: true,
                symbolSize: 8,
                endLabel: {
                    show: false
                }
            })
        })
        a.push({
            name: "股價",
            type: 'line',
            data: result.xy_prices.map(row => row.y),
            smooth: false,
            showSymbol: true,
            symbolSize: 8,
            endLabel: {
                show: false
            },
            markLine: {
                symbol: "none",
                data: [{
                    yAxis: result.big_price.price,
                    lineStyle: {
                        color: grey[500],
                        width: 4,
                        type: "dashed"
                    },
                    label: {
                        position: "insideStartTop",
                        show: true,
                        formatter: '今日股價'
                    }
                }]
            }
        })
        // a.push(series)
        k["series"] = a
        return k;
    }


    function genOption2() {
        let k = {
            animation: false,
            tooltip: {
                show: true
            },
            legend: {
                show: true,
                bottom: 0,
                icon: 'rect'
            },
            grid: [{
                top: 10,
                left: 0,
                right: 20,
                bottom: 40,
                containLabel: true
            }],
            axisPointer: {
                show: true
            },
            xAxis: [{
                type: 'category',
                boundaryGap: false,
                data: result.x_value,
            }],
            yAxis: [{
                scale: true,
                type: 'value',
                position: 'left',
                show: true,
            }],
            dataZoom: {
                type: 'inside',
                xAxisIndex: [0, 1],
                start: 40,
                end: 100
            }
        };

        let a = []
        if (result.history != null) {
            result.history.filter(v => v.day == 2 && v.algorithm === alignment).map(row => {
                a.push({
                    name: row.day + "日預測",
                    type: 'line',
                    data: row.predictions.map(vv => [vv.x, vv.y]),
                    smooth: false,
                    showSymbol: false,
                    symbolSize: 8,
                    endLabel: {
                        show: false
                    },
                    connectNulls: true
                })
            })
            result.history.filter(v => v.day == 4 && v.algorithm === alignment).map(row => {
                a.push({
                    name: row.day + "日預測",
                    type: 'line',
                    data: row.predictions.map(vv => [vv.x, vv.y]),
                    smooth: false,
                    showSymbol: false,
                    symbolSize: 8,
                    endLabel: {
                        show: false
                    },
                    connectNulls: true
                })
            })

            result.history.filter(v => v.day == 6 && v.algorithm === alignment).map(row => {
                a.push({
                    name: row.day + "日預測",
                    type: 'line',
                    data: row.predictions.map(vv => [vv.x, vv.y]),
                    smooth: false,
                    showSymbol: false,
                    symbolSize: 8,
                    endLabel: {
                        show: false
                    },
                    connectNulls: true
                })
            })

        }
        if (result != null && result.big_history_prices != null) {
            a.push({
                name: "股價",
                type: 'candlestick',
                data: result.big_history_prices.reverse().map(vv => [vv.o, vv.price, vv.l, vv.h]),
                smooth: false,
                showSymbol: false,
                symbolSize: 8,
                endLabel: {
                    show: false
                },
                connectNulls: true
            })
        }

        k["series"] = a
        return k;
    }

    const fetchData = () => {
        let param = {
            algorithm: alignment
        }

        if (localStorage.getItem("ml_lic") != null) {
            param['lic'] = localStorage.getItem("ml_lic")
        }

        fetch("/ml/v1/prediction/big", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null) {
                        setResult(result)
                    }
                }
            );
    }


    useEffect(() => {
        fetchData()
    }, [alignment]);

    useEffect(() => {
        if (result != null) {
            setOption(genoption1)
            setOption2(genOption2)
        }
    }, [result]);

    const getColor = () => {
        if (result != null && result.price != null) {
            if (result.price.i > 0) return "red"
            else if (result.price.i < 0) return "green"
            else return null
        } else return null
    }

    const getInv = () => {
        if (result != null && result.price != null) {
            if (result.price.i > 0) return "▲" + result.price.i + '元'
            else if (result.price.i < 0) return "▼" + result.price.i + '元'
            else return result.price.i + '元'
        } else if (result.price != null)
            return result.price.i + '元'
        else return '▲0元'
    }

    const getInv1 = (row, sv) => {
        if (row != null && row.big_price != null) {
            let pp = (((sv - row.big_price.price) * 100) / row.big_price.price).toFixed(1)
            let k = (sv - row.big_price.price).toFixed(2)

            if (k > 0) return "▲" + k + ' (' + pp + '%)'
            else if (k < 0) return "▼" + k + ' (' + pp + '%)'
            else return k + '元' + ' (' + pp + '%)'
        } else return '▲0元'
    }

    return (
        <Box sx={{ width: "100%", position: 'absolute', top: Height.header, bottom: props.ismobile || fullScreen ? 60 : 0, overflowY: 'auto' }}>
            <Stack direction={"row"} spacing={1} sx={{ p: 2, }}>
                <Typography fontSize={28}>大盤預測</Typography>
                {result != null && <Typography sx={{ alignSelf: "center" }} fontSize={16}>整體上漲率：{result.up_rate}%</Typography>}

            </Stack>
            <Grid container spacing={2} sx={{ p: 1, width: "100%" }} >
                <Grid xs={12}>
                    <Box>
                        {option != null ?
                            <ReactEChartsCore
                                theme={theme.palette.mode}
                                echarts={echarts}
                                option={option}
                                notMerge={true}
                                lazyUpdate={false}
                                style={{ height: '50vh' }}
                            // onEvents={onEvents}
                            />
                            : null}
                    </Box>
                </Grid>

                <Grid xs={12}>
                    <Tabs variant="scrollable"
                        scrollButtons="auto" value={value} onChange={handleTabChange} >
                        <Tab label="OLS" {...a11yProps(0)} />
                        <Tab label="DecisionTree" {...a11yProps(1)} />
                        <Tab label="LASSO" {...a11yProps(2)} />
                        <Tab label="SVR" {...a11yProps(3)} />
                        <Tab label="Ridge" {...a11yProps(4)} />
                        <Tab label="ElasticNet" {...a11yProps(5)} />
                        <Tab label="GaussianProcessRegression" {...a11yProps(6)} />
                        <Tab label="RBFNetwork" {...a11yProps(7)} />
                        <Tab label="RandomForest" {...a11yProps(8)} />
                        <Tab label="LSTM" {...a11yProps(9)} />
                        <Tab label="CNN" {...a11yProps(10)} />
                    </Tabs>



                    {option2 != null ?
                        <ReactEChartsCore
                            theme={theme.palette.mode}
                            echarts={echarts}
                            option={option2}
                            notMerge={true}
                            lazyUpdate={false}
                            style={{ height: '50vh' }}
                        // onEvents={onEvents}
                        />
                        : null}
                </Grid>


                <Grid xs={12} md={12}>
                    {result != null && result.av != null && <TableContainer component={Paper}>
                        <Table sx={{}} size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ zIndex: 999, position: "sticky", left: 0, whiteSpace: "nowrap", fontSize: 16, fontWeight: "bold", bgcolor: theme.palette.mode === "light" ? grey[50] : grey[900], color: theme.palette.mode === "light" ? "#000000" : "#FFFFFF" }}>策略名稱</TableCell>
                                    <TableCell align="right" sx={{ whiteSpace: "nowrap", fontSize: 16, fontWeight: "bold" }}>2日預估</TableCell>
                                    <TableCell align="right" sx={{ whiteSpace: "nowrap", fontSize: 16, fontWeight: "bold" }}>2日準確度(近40日)</TableCell>
                                    <TableCell align="right" sx={{ whiteSpace: "nowrap", fontSize: 16, fontWeight: "bold" }}>4日預估</TableCell>
                                    <TableCell align="right" sx={{ whiteSpace: "nowrap", fontSize: 16, fontWeight: "bold" }}>4日準確度(近40日)</TableCell>
                                    <TableCell align="right" sx={{ whiteSpace: "nowrap", fontSize: 16, fontWeight: "bold" }}>6日預估</TableCell>
                                    <TableCell align="right" sx={{ whiteSpace: "nowrap", fontSize: 16, fontWeight: "bold" }}>6日準確度(近40日)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {result.av.map((row) => (
                                    <TableRow
                                        key={row.n}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row" sx={{ zIndex: 998, position: "sticky", left: 0, bgcolor: theme.palette.mode === "light" ? grey[50] : grey[900], color: theme.palette.mode === "light" ? "#000000" : "#FFFFFF", whiteSpace: "nowrap" }}>
                                            {row.n}
                                        </TableCell>
                                        <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
                                            <Typography color={result.big_price != null && row.d > result.big_price.price ? red[400] : green[400]} fontSize={16} sx={{ fontWeight: 'normal', alignSelf: 'right' }} >{row.d}元</Typography>
                                            <Typography color={result.big_price != null && row.d > result.big_price.price ? red[400] : green[400]} fontSize={12} sx={{ fontWeight: 'normal', alignSelf: 'right' }} >{getInv1(result, row.d)}元</Typography>
                                        </TableCell>
                                        <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
                                            <Typography fontSize={16} sx={{ fontWeight: 'normal', alignSelf: 'right' }} >{row.a2}%</Typography>
                                        </TableCell>
                                        <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
                                            <Typography color={result.big_price != null && row.d1 > result.big_price.price ? red[400] : green[400]} fontSize={16} sx={{ fontWeight: 'normal', alignSelf: 'right' }} >{row.d1}元</Typography>
                                            <Typography color={result.big_price != null && row.d1 > result.big_price.price ? red[400] : green[400]} fontSize={12} sx={{ fontWeight: 'normal', alignSelf: 'right' }} >{getInv1(result, row.d1)}元</Typography>
                                        </TableCell>
                                        <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
                                            <Typography fontSize={16} sx={{ fontWeight: 'normal', alignSelf: 'right' }} >{row.a4}%</Typography>
                                        </TableCell>
                                        <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
                                            <Typography color={result.big_price != null && row.d2 > result.big_price.price ? red[400] : green[400]} fontSize={16} sx={{ fontWeight: 'normal', alignSelf: 'right' }} >{row.d2}元</Typography>
                                            <Typography color={result.big_price != null && row.d2 > result.big_price.price ? red[400] : green[400]} fontSize={12} sx={{ fontWeight: 'normal', alignSelf: 'right' }} >{getInv1(result, row.d2)}元</Typography>

                                        </TableCell>
                                        <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
                                            <Typography fontSize={16} sx={{ fontWeight: 'normal', alignSelf: 'right' }} >{row.a6}%</Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>}
                </Grid>

            </Grid>
        </Box>
    );
}

