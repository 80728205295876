import React, { useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/system';
import { Button, Divider, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import { CheckBox } from '@mui/icons-material';
import Stack from '@mui/material/Stack';
import { purple, deepOrange, green, red, blueGrey, blue, pink, grey } from '@mui/material/colors';
import Avatar from '@mui/material/Avatar';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { getId, numberWithCommas } from '../util/ID'
import { putInpQueryParam } from '../util/ID'
import { useTheme } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Height } from '../util/ScreenHeight'
import SellDialog from './SellDialog'
import AlertD from '../util/AlertD'
import BuyRuleDialog from './BuyRuleDialog'
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import BuyDialog1 from './BuyDialog1'
import ButtonGroup from '@mui/material/ButtonGroup';

import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart, BarChart, CandlestickChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    MarkAreaComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';
import DetailKChart from '../rec/DetailKChart'

echarts.use(
    [VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, TitleComponent, TooltipComponent, GridComponent, LineChart, CandlestickChart, CanvasRenderer, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent, MarkAreaComponent, BarChart]
);

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        // fontWeight:"bold",
        fontSize: 18,
        whiteSpace: "nowrap"
        // backgroundColor: "#313f57",
        // color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 15,
        whiteSpace: "nowrap",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


export default function SellListTable(props) {
    const [page, setPage] = React.useState(1);
    const [total, setTotal] = React.useState(1)
    const [result, setResult] = React.useState(null);
    const [rate, setRate] = React.useState(null);
    const [option, setOption] = React.useState(null);

    const [loading, setLoading] = React.useState(false)

    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);
    const [openSetting, setOpenSetting] = React.useState(false);
    const [openSetting1, setOpenSetting1] = React.useState(false);
    const [openSetting2, setOpenSetting2] = React.useState(false);
    const [row, setRow] = React.useState(null);

    const [openSetting3, setOpenSetting3] = React.useState(false);
    const [sid, setSid] = React.useState(null);

    const [age, setAge] = React.useState("");

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const getRank = () => {
        return localStorage.getItem("mv_rank") != null ? localStorage.getItem("mv_rank") : 30
    }

    function genoption1() {
        let k = {
            animation: false,
            title: {
                text: '累積盈虧'
            },

            grid: [{
                top: 50,
                left: 5,
                right: 20,
                bottom: 0,
                containLabel: true
            }],
            xAxis: [{
                type: 'category',
                boundaryGap: false,
                data: rate.profit_list.map(row => row.x),
            }],
            yAxis: [{
                scale: true,
                type: 'value',
                position: 'left',
                show: true,
                splitLine: {
                    show: true,
                    lineStyle: {
                        opacity: 0.2
                    }
                }
            }],
            series: [
                {
                    name: '累積盈虧',
                    type: 'line',
                    data: rate.profit_list.map(row => row.y),
                    smooth: true,
                    showSymbol: false,
                }
            ]
        };
        return k;
    }

    useEffect(() => {
        // fetchData()
        if (rate != null) setOption(genoption1)
    }, [rate]);

    const fetchData = () => {
        setLoading(true)

        let param = {
            page: page,
            sn: getId(),
        }

        if (localStorage.getItem("ml_lic") != null) {
            param['lic'] = localStorage.getItem("ml_lic")
        }


        fetch("/move/v1/app/sell_list", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    setLoading(false)

                    if (result != null) {
                        if (result.l != null && result.l.length > 0) {
                            // setTotal(result.total_page)
                            setTotal(0)
                            setResult(result.l)
                            setRate(result.rate)
                            // setBuyNum(result.count)
                        } else {
                            setTotal(0)
                            setResult([])
                            setRate(null)
                        }
                    }
                }
            );
    }

    const clickDel = (id) => {
        let param = {
            sn: getId(),
            id: id,
        }

        if (localStorage.getItem("ml_lic") != null) {
            param['lic'] = localStorage.getItem("ml_lic")
        }

        fetch("/move/v1/stran/del", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    fetchData()
                }
            );

    }


    useEffect(() => {
        fetchData()
    }, [page]);

    

    const getColor1 = (value) => {
        if (value > 0) return red[400]
        else if (value < 0) return green[400]
        else return null
    }

    const openDetail = (row) => {
        setRow(row)
        setOpenSetting(true)
    }

    const roi = (bprice, nprice) => {
        return (((nprice / bprice) - 1) * 100).toFixed(2)
    }

    const isSell = (row) => {
        return row.aft == null || row.aft.avg_err || row.aft.gap_err || row.aft.rank > getRank()
    }

    const isRankErr = (row) => {
        return (row.aft == null || row.aft.rank > getRank())
    }

    const openDetail3 = (id) => {
        setSid(id)
        setOpenSetting3(true)
    }

    return (
        <>
            {openSetting ? <SellDialog row={row} refresh={fetchData} open={openSetting} close={setOpenSetting}></SellDialog> : null}
            {openSetting1 ? <BuyRuleDialog row={row} refresh={fetchData} open={openSetting1} close={setOpenSetting1}></BuyRuleDialog> : null}
            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}
            {openSetting2 ? <BuyDialog1 row={row} refresh={fetchData} open={openSetting2} close={setOpenSetting2}></BuyDialog1> : null}
            {openSetting3 && <DetailKChart  sid={sid} open={openSetting3} close={setOpenSetting3} />}            


            <Box sx={{ p: 0, width: "100%", overflowY: 'hidden' }}>
                <TableContainer component={Paper} sx={{ position: 'absolute', top: Height.header, bottom: props.ismobile || fullScreen ? 100 : 55, mt: 1.5 }}>
                    <div>
                        <Typography sx={{ mt: 3, textAlign: "center" }} variant="h4">已實現損益</Typography>
                        {/* <Divider></Divider> */}
                        {rate != null && <ButtonGroup variant="text" aria-label="text button group" sx={{ width: '100%', mt: 0, mb: 0, justifyContent: 'center' }}>
                            <Button sx={{ width: "33.3%" }} color="inherit" key={"1"} >
                                <Stack sx={{ justifyContent: 'center', width: "100%" }} key={"1"}>
                                    <Typography variant="caption" sx={{ alignSelf: 'center', flexGrow: 1 }}  >報酬率%</Typography>
                                    <Typography sx={{ color: rate.roi > 0 ? red[500] : green[500], alignSelf: 'center', fontSize: 30, fontWeight: 'bold' }}>{rate.roi}</Typography>
                                </Stack>
                            </Button>
                            <Button sx={{ width: "33.3%" }} color="inherit" key={"2"} >
                                <Stack sx={{ justifyContent: 'center', width: "100%" }} key={"2"}>
                                    <Typography variant="caption" sx={{ alignSelf: 'center', flexGrow: 1 }}  >勝率%</Typography>
                                    <Typography sx={{ alignSelf: 'center', fontSize: 30, fontWeight: 'bold' }}>{rate.win_rate}</Typography>
                                </Stack>
                            </Button>
                            <Button sx={{ width: "33.3%" }} color="inherit" key={"3"} >
                                <Stack sx={{ justifyContent: 'center', width: "100%" }} key={"3"}>
                                    <Typography variant="caption" sx={{ alignSelf: 'center', flexGrow: 1 }}  >期望值(元)</Typography>
                                    <Typography sx={{ color: rate.roi > 0 ? red[500] : green[500], alignSelf: 'center', fontSize: 30, fontWeight: 'bold' }}>{rate.expect}</Typography>
                                </Stack>
                            </Button>
                        </ButtonGroup>
                        }
                        <Divider />

                        {/* <Typography sx={{ mt: 1, textAlign: "center" }} variant="body1">累積盈虧
                        </Typography> */}

                        {option != null ?
                            <ReactEChartsCore
                                theme={theme.palette.mode}
                                echarts={echarts}
                                option={option}
                                notMerge={true}
                                lazyUpdate={false}
                                style={{ height: '25vh', marginTop: 20 }}
                            // onEvents={onEvents}
                            />
                            : null}
                    </div>
                    <Table sx={{ mt: 2 }} stickyHeader size="small">

                        <TableHead>

                            <TableRow >
                                <StyledTableCell align="left">賣出日期</StyledTableCell>
                                <StyledTableCell>股票</StyledTableCell>
                                <StyledTableCell >類股</StyledTableCell>

                                <StyledTableCell align="right">報酬率</StyledTableCell>

                                <StyledTableCell align="right">買進價格</StyledTableCell>
                                <StyledTableCell align="right">賣出價格</StyledTableCell>
                                <StyledTableCell align="right">賣出數量</StyledTableCell>
                                <StyledTableCell align="right">剩餘數量</StyledTableCell>
                                <StyledTableCell align="center">動作</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {result != null && result.map((row) => (
                                <StyledTableRow key={row.id}>
                                    <StyledTableCell align="left">
                                        {row.symd}
                                    </StyledTableCell>


                                    <StyledTableCell  onClick={e => openDetail3(row.company.stock_no)}>
                                        <div>{row.company.short_name} {row.company.stock_no}</div>
                                    </StyledTableCell>
                                    <StyledTableCell >
                                        {row.company.inductry}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Typography sx={{ fontSize: 15, whiteSpace: "nowrap" }} color={getColor1(roi(row.bprice, row.sprice))}>{roi(row.bprice, row.sprice)}%</Typography>

                                    </StyledTableCell>

                                    <StyledTableCell align="right">
                                        {row.bprice}元
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {row.sprice}元
                                    </StyledTableCell>


                                    <StyledTableCell align="right">
                                        {row.num}股
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {row.bnum}股
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <Button onClick={e => clickDel(row.id)} disableElevation variant='contained' color="inherit" sx={{ width: 100, bgcolor: grey[300], color: "#000000" }} >{"刪除"} </Button>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            {
                result != null && result.length > 0 && < Box style={{ flexShrink: 0 }} sx={{ position: 'absolute', bottom: props.ismobile || fullScreen ? 60 : 0, width: '100%', mt: 0.5, mb: 0.5, display: 'flex', justifyContent: "center" }}>
                    <Pagination size="small" siblingCount={1} boundaryCount={1} count={total} variant="outlined" shape="rounded" onChange={handlePageChange} />
                </Box>
            }
        </>
    );
}
