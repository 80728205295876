import React, { useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/system';
import { Button, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import { CheckBox } from '@mui/icons-material';
import Stack from '@mui/material/Stack';
import { purple, deepOrange, green, red, blueGrey, blue, pink, grey } from '@mui/material/colors';
import Avatar from '@mui/material/Avatar';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { getId, numberWithCommas } from '../util/ID'
import { putInpQueryParam } from '../util/ID'
import { useTheme } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Height } from '../util/ScreenHeight'
import SellDialog from './SellDialog'
import AlertD from '../util/AlertD'
import BuyRuleDialog from './BuyRuleDialog'
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import BuyDialog2 from './BuyDialog2'
import DetailKChart from '../rec/DetailKChart'
import ButtonGroup from '@mui/material/ButtonGroup';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LoveSetting from './LoveSetting';
import ConfirmD from '../util/ConfirmD';
import TranDetail from './TranDetail';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        // fontWeight:"bold",
        fontSize: 15,
        whiteSpace: "nowrap",
        // backgroundColor: "#313f57",
        // color: theme.palette.common.white,
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,

    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 15,
        whiteSpace: "nowrap",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


export default function BuyListTable(props) {
    const [page, setPage] = React.useState(1);
    const [total, setTotal] = React.useState(1)
    const [result, setResult] = React.useState(null);
    const [loading, setLoading] = React.useState(false)

    const [showAlert, setShowAlert] = React.useState(false);
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);
    const [openSetting, setOpenSetting] = React.useState(false);
    const [openSetting1, setOpenSetting1] = React.useState(false);
    const [openSetting2, setOpenSetting2] = React.useState(false);
    const [openSetting3, setOpenSetting3] = React.useState(false);
    const [openSetting4, setOpenSetting4] = React.useState(false);

    const [row, setRow] = React.useState(null);
    const [sid, setSid] = React.useState(null);

    const [age, setAge] = React.useState("");
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const del = () => {
        setAlertMsg("確定刪除?")
        setShowConfirm(true)
    }

    const delOk = () => {
        setShowConfirm(false)

        let param = {
            sn: getId(),
            id: row.love.id
        }

        if (localStorage.getItem("ml_lic") != null) {
            param['lic'] = localStorage.getItem("ml_lic")
        }


        fetch("/ml/v1/love/del", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    fetchData()
                }
            );

    }

    const fetchData = () => {
        setLoading(true)

        let param = {
            page: page,
            sn: getId(),
            inp: {}
        }

        if (localStorage.getItem("ml_lic") != null) {
            param['lic'] = localStorage.getItem("ml_lic")
        }

        param = putInpQueryParam(param)

        fetch("/ml/v1/love/get", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    setLoading(false)

                    if (result != null) {
                        if (result.data != null && result.data.length > 0) {
                            // setTotal(result.total_page)
                            setTotal(0)
                            setResult(result.data)
                            // setBuyNum(result.count)
                        } else {
                            setTotal(0)
                            setResult([])
                        }
                    }
                }
            );
    }

    useEffect(() => {
        fetchData()
    }, [page]);

    const getColor = (row) => {
        if (row != null && row.price != null) {
            if (row.price.i > 0) return red[400]
            else if (row.price.i < 0) return green[400]
            else return null
        } else return null
    }

    const getInv = (row) => {
        if (row != null && row.price != null) {
            let pp = ((row.price.i * 100) / (row.price.p - row.price.i)).toFixed(1)

            if (row.price.i > 0) return "▲" + row.price.i + ' (' + pp + '%)'
            else if (row.price.i < 0) return "▼" + row.price.i + ' (' + pp + '%)'
            else return row.price.i + '元' + ' (' + pp + '%)'
        } else return '▲0元'
    }

    const getColor1 = (value) => {
        if (value > 0) return red[400]
        else if (value < 0) return green[400]
        else return null
    }

    const openDetail = () => {
        setAnchorEl(null);
        // setRow(row)
        setOpenSetting(true)
    }

    const openDetail3 = (id) => {
        setSid(id)
        setOpenSetting3(true)
    }

    const openDetail4 = (row) => {
        setRow(row)
        setOpenSetting4(true)
    }

    const convertPiece = (v) => {
        if (v >= 1000) return (v / 1000).toFixed(1) + "張"
        else return v + "股"
    }

    const handleMenuClick = (event, row) => {
        setAnchorEl(event.currentTarget);
        setRow(row)
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const getStatusString = (status, dir) => {
        if (status === 0) return "第1單位進場"
        else if (status === 1) return "第2單位加碼"
        else if (status === 2) return "第3單位加碼"
        else if (status === 3) return "第4單位加碼"
        else if (status === 4) return "停損"
        else if (status === 5) return "出場"
        else return ""
    }

    const getStatusColor = (roi) => {
        if (roi > 0) return "error"
        else if (roi < 0) return "success"
        else return "inherit"
    }

    const getSystemWord = (love) => {
        if (love != null) {
            let dirWord = "多方"
            let systemWord = "系統Ⅰ"
            if (love.dir === 1) dirWord = "空方"
            if (love.system === 1) systemWord = "系統Ⅱ"
            return systemWord + " - " + dirWord
        } else return "-"
    }

    return (
        <>
            {openSetting ? <LoveSetting row={row} refresh={fetchData} open={openSetting} close={setOpenSetting}></LoveSetting> : null}
            {openSetting1 ? <BuyRuleDialog row={row} refresh={fetchData} open={openSetting1} close={setOpenSetting1}></BuyRuleDialog> : null}
            {openSetting2 ? <BuyDialog2 row={row} refresh={fetchData} open={openSetting2} close={setOpenSetting2}></BuyDialog2> : null}
            {openSetting3 && <DetailKChart sid={sid} open={openSetting3} close={setOpenSetting3} />}
            {openSetting4 && <TranDetail row={row} sid={sid} open={openSetting4} close={setOpenSetting4} />}

            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}
            {showConfirm ? <ConfirmD desc={alertMsg} open={showConfirm} close={setShowConfirm} ok={delOk}></ConfirmD> : null}


            <Box sx={{ p: 0, width: "100%", overflow: 'hidden' }}>
                <Typography sx={{ mt: 3, textAlign: "center" }} variant="h4">海龜交易
                    <IconButton onClick={e => setOpenSetting2(true)}>
                        <AddIcon />
                    </IconButton>
                </Typography>

                <TableContainer component={Paper} sx={{ position: 'absolute', top: 60 + Height.header, bottom: props.ismobile || fullScreen ? 100 : 55, mt: 1.5 }}>
                    <Table sx={{ minWidth: 700 }} stickyHeader size="small">
                        <TableHead>
                            <TableRow >
                                <StyledTableCell>交易日</StyledTableCell>

                                <StyledTableCell>股票</StyledTableCell>
                                <StyledTableCell align="center">系統多空</StyledTableCell>
                                <StyledTableCell align="center" >狀態</StyledTableCell>

                                <StyledTableCell align="right">ATR</StyledTableCell>
                                <StyledTableCell align="right">1單位</StyledTableCell>
                                <StyledTableCell align="right">1單位資金</StyledTableCell>
                                <StyledTableCell align="center">加碼點</StyledTableCell>
                                <StyledTableCell align="center">出場點</StyledTableCell>
                                <StyledTableCell align="center">停損點</StyledTableCell>
                                <StyledTableCell align="center">動作</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {result != null && result.map((row) => {
                                return row.company != null && <StyledTableRow key={row.id}>
                                    <StyledTableCell align="left">
                                        {row.love != null ? row.love.ymd : "-"}
                                    </StyledTableCell>

                                    <StyledTableCell onClick={e => openDetail3(row.company.stock_no)}>
                                        {row.company != null && <div>{row.company.short_name} {row.company.stock_no}</div>}

                                        <Stack direction='row' spacing={0.5} sx={{ pr: 1, alignSelf: 'center' }}>
                                            <Typography color={getColor(row)} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }}>{row.price != null ? row.price.p : "-"}</Typography>
                                            <Typography color={getColor(row)} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }}>{getInv(row)}</Typography>
                                        </Stack>
                                    </StyledTableCell>

                                    <StyledTableCell align="left">
                                        <Button onClick={e => openDetail4(row)} disableElevation variant="text" fullWidth color={row.love != null && row.love.dir === 0 ? "error" : "success"}>{getSystemWord(row.love)}</Button>
                                    </StyledTableCell>

                                    <StyledTableCell align="center">
                                        {row.tran != null && row.earn != null && <Button onClick={e => openDetail4(row)} disableElevation variant="contained" fullWidth color={getStatusColor(row.earn.roi)}>{getStatusString(row.tran.tp)} ({row.earn.roi}%)</Button>}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Typography sx={{ fontSize: 15, whiteSpace: "nowrap" }} >{row.meta != null ? row.meta.atr : "-"}元</Typography>
                                    </StyledTableCell>


                                    {/* <StyledTableCell align="right">
                                        {(row.meta.atr * 1000).toFixed(0)}元
                                    </StyledTableCell> */}
                                    <StyledTableCell align="right">{row.meta != null ? convertPiece(row.meta.buy_unit): "-"}</StyledTableCell>
                                    <StyledTableCell align="right">{row.price != null && row.meta != null ? numberWithCommas((row.meta.buy_unit * row.price.p).toFixed(0)) : "-"} 元
                                    </StyledTableCell>

                                    <StyledTableCell align="right">
                                        
                                        {row.al != null && <ButtonGroup variant="text" color='error' onClick={e => openDetail4(row)}>
                                            <Button>{row.al.a1}元</Button>
                                            <Button>{row.al.a2}元</Button>
                                            <Button>{row.al.a3}元</Button>
                                        </ButtonGroup>}

                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Button fullWidth disableElevation variant='text' color="error">{row.earn != null ? row.earn.out_price : "-"}元</Button>
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {row.al != null && row.price != null && <Button fullWidth disableElevation variant='text' color="success">{row.al.l}元 ({(((row.price.p - row.al.l) * 100) / row.price.p).toFixed(1)}%)</Button>}
                                    </StyledTableCell>


                                    <StyledTableCell align="center">
                                        <IconButton sx={{}} onClick={e => handleMenuClick(e, row)}>
                                            <MoreVertIcon />
                                        </IconButton>
                                    </StyledTableCell>
                                </StyledTableRow>
                            })}
                            <Menu
                                anchorEl={anchorEl}
                                open={openMenu}
                                onClose={handleMenuClose}
                            >

                                <MenuItem onClick={e => openDetail()}>設定</MenuItem>
                                <MenuItem onClick={e => del()}>刪除</MenuItem>
                            </Menu>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            {result != null && result.length > 0 && < Box style={{ flexShrink: 0 }} sx={{ position: 'absolute', bottom: props.ismobile || fullScreen ? 60 : 0, width: '100%', mt: 0.5, mb: 0.5, display: 'flex', justifyContent: "center" }}>
                <Pagination size="small" siblingCount={1} boundaryCount={1} count={total} variant="outlined" shape="rounded" onChange={handlePageChange} />
            </Box>}
        </>
    );
}
