import React, { useEffect, useRef } from 'react';
import { Height } from '../util/ScreenHeight'
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import { Divider, List, ListItem, Typography } from '@mui/material';
import { getId } from '../util/ID'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Stack } from '@mui/system';

export default function CLanding(props) {
    const [page, setPage] = React.useState(1);
    const [total, setTotal] = React.useState(100)

    const [showEmpty, setShowEmpty] = React.useState(false);
    const [emptyMsg, setEmptyMsg] = React.useState('');
    const [result, setResult] = React.useState(null);
    const [menu, setMenu] = React.useState(null)
    const [loading, setLoading] = React.useState(false)

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const fetchData = () => {
    }

    useEffect(() => {
        fetchData()
    }, []);


    return (
        <Box style={{ flexDirection: 'column', position: 'absolute', width: '100%', top: Height.header + 10, bottom: fullScreen ? 55 : 0, overflowY: 'scroll' }} sx={{}}>
            <Box sx={{ pl: 2, pr: 2 }}>
                {/* <Typography variant='h5' fontWeight={"bold"}>何謂動能投資法</Typography>
                <Typography variant='body1'>動能投資法是由Andreas F. Clenow所發明</Typography> */}

                <Typography sx={{ mt: 2, textAlign: "center" }} variant='h4' fontWeight={"bold"}>軟體畫面說明</Typography>
                <Typography sx={{ mt: 2 }} variant='body1' >機器學習投資法是從"海龜交易法則"書本中看到原版的海龜交易法則後，針對台灣股市設計的一套投資軟體。下面會針對一些重要表格與操作來說明，若需要更詳盡的解說或原理的解釋，可以直接購買此書獲得解答。</Typography>
                {/* <img src='' style={{ width: "500px" }} /> */}

                <Box
                    component="img"
                    sx={{
                        // height: 233,
                        // width: 350,
                        maxHeight: { xs: '40vh', md: '70vh' },
                        maxWidth: { xs: '40wh', md: '70wh' },
                    }}
                    alt="book"
                    src="book.png"
                />


                <Typography sx={{ mt: 10 }} variant='h5' fontWeight={"bold"}>策略</Typography>
                <Typography variant='body1'>可以藉由選擇系統Ⅰ或系統Ⅱ與多空來篩選有興趣的股票。</Typography>

                <Stack direction={"column"} spacing={0.2} sx={{ p: 2 }}>
                    <Typography variant='body1'>系統Ⅰ：創20日新高。</Typography>
                    <Typography variant='body1'>系統Ⅱ：創55日新高。</Typography>
                </Stack>
                <Box
                    component="img"
                    sx={{
                        // height: 233,
                        // width: 350,
                        maxHeight: { xs: '33vh', md: '70vh' },
                        maxWidth: { xs: '33wh', md: '70vw' },
                    }}
                    src="system.png"
                />

                <Typography sx={{ mt: 10 }} variant='h5' fontWeight={"bold"}>設定</Typography>
                <Typography variant='body1'>可以藉由設定重新計算每個單位的價值與加入其他過濾條件。</Typography>


                <Box
                    component="img"
                    sx={{
                        // height: 233,
                        // width: 350,
                        maxHeight: { xs: '80vh', md: '80vh' },
                        maxWidth: { xs: '80wh', md: '80wh' },
                    }}
                    src="system1.png"
                />
                {/* <img src='mov_table1.png' style={{ width: "550px", objectFit: "contain" }} /> */}
                <Stack direction={"column"} spacing={1} sx={{ p: 2 }}>
                    <Typography variant='body1'>■ 名目帳戶：總資金</Typography>
                    <Typography variant='body1'>■ 帳戶%：名目帳戶百分之多少當作上漲或下跌1個單位的價值。</Typography>
                    <Typography variant='body1'>■ ATR：20日平均真實範圍（Average True Range），用海龜的ATR公式，不用現在的公式。</Typography>
                    <Typography variant='body1'>■ 其他：其他股票過濾規則。</Typography>
                </Stack>

                <Typography sx={{ mt: 10 }} variant='h5' fontWeight={"bold"}>海龜表</Typography>
                <Typography variant='body1'>顯示系統Ⅰ或系統Ⅱ與多空來篩選的股票。</Typography>

                {/* <Stack direction={"column"} spacing={0.2} sx={{ p: 2 }}>
                     <Typography variant='body1'>系統Ⅰ：創20日新高。</Typography>
                    <Typography variant='body1'>系統Ⅱ：創55日新高。</Typography>
                </Stack> */}
                <Box
                    component="img"
                    sx={{
                        // height: 233,
                        // width: 350,
                        maxHeight: { xs: '70vh', md: '70vh' },
                        maxWidth: { xs: '70wh', md: '70vw' },
                    }}
                    alt="table1"
                    src="table1.png"
                />
                {/* <img src='mov_table1.png' style={{ width: "550px", objectFit: "contain" }} /> */}
                <Stack direction={"column"} spacing={1} sx={{ p: 2 }}>
                    <Typography variant='body1'>■ 股票：股票名稱與股價。點擊可以出現K線圖</Typography>
                    <Typography variant='body1'>■ 類別：股票所在的類別。依據分散風險的概念，不希望大部分的股票在同一類別中。</Typography>
                    <Typography variant='body1'>■ 20日：創20日新高或新低的標示。</Typography>
                    <Typography variant='body1'>■ 55日：創55日新高或新低的標示。</Typography>
                    <Typography variant='body1'>■ ATR：用海龜的ATR公式算出的20日平均真實區間。</Typography>
                    <Typography variant='body1'>■ 1單位：買一個單位是要買多少股(張)股票。</Typography>
                    <Typography variant='body1'>■ 1單位資金：購買1個單位需要多少資金。</Typography>
                    <Typography variant='body1'>■ 加入交易：加入海龜交易觀察名單。</Typography>
                </Stack>


                <Typography sx={{ mt: 10 }} variant='h5' fontWeight={"bold"}>海龜交易(上)</Typography>
                <Typography variant='body1'>顯示進行海龜交易的股票。</Typography>

                <Box
                    component="img"
                    sx={{
                        // height: 233,
                        // width: 350,
                        maxHeight: { xs: '70vh', md: '70vh' },
                        maxWidth: { xs: '70wh', md: '70vw' },
                    }}
                    src="tran1.png"
                />
                <Stack direction={"column"} spacing={1} sx={{ p: 2 }}>
                    <Typography variant='body1'>■ 交易日：執行海龜交易的開始日期。</Typography>
                    <Typography variant='body1'>■ 股票：股票名稱與股價。</Typography>
                    <Typography variant='body1'>■ 系統多空：使用哪個系統跟做多或做空。</Typography>
                    <Typography variant='body1'>■ 狀態：目前交易的最新狀態與報酬率是多少。</Typography>
                    <Typography variant='body1'>■ ATR：用海龜的ATR公式算出的20日平均真實區間。</Typography>
                    <Typography variant='body1'>■ 1單位：買一個單位是要買多少股(張)股票。</Typography>
                    <Typography variant='body1'>■ 1單位資金：購買1個單位需要多少資金。</Typography>
                </Stack>



                <Typography sx={{ mt: 10 }} variant='h5' fontWeight={"bold"}>海龜交易(下)</Typography>
                <Typography variant='body1'>顯示進行海龜交易的股票。</Typography>

                <Box
                    component="img"
                    sx={{
                        maxHeight: { xs: '70vh', md: '70vh' },
                        maxWidth: { xs: '70wh', md: '70vw' },
                    }}
                    src="tran2.png"
                />
                <Stack direction={"column"} spacing={1} sx={{ p: 2 }}>
                    <Typography variant='body1'>■ 加碼點：第1，2，3次加碼的股價。</Typography>
                    <Typography variant='body1'>■ 出場點：系統Ⅰ多空出場點為近10日新低或新高，系統ⅠⅠ多空出場點為近20日新低或新高。</Typography>
                    <Typography variant='body1'>■ 停損點：停損的股價，初期預設為2倍ATR。</Typography>
                </Stack>



                <Typography sx={{ mt: 10 }} variant='h5' fontWeight={"bold"}>海龜交易設定</Typography>
                <Typography variant='body1'>個別設定每一筆海龜交易的條件。</Typography>

                <Box
                    component="img"
                    sx={{
                        maxHeight: { xs: '70vh', md: '70vh' },
                        maxWidth: { xs: '70wh', md: '70vw' },
                    }}
                    src="setting.png"
                />
                <Stack direction={"column"} spacing={1} sx={{ p: 2 }}>
                    <Typography variant='body1'>■ 交易日期：可以設定第一次進場的時間。</Typography>
                    <Typography variant='body1'>■ 系統：系統Ⅰ或系統ⅠⅠ。</Typography>
                    <Typography variant='body1'>■ 多空：多方或空方。</Typography>
                    <Typography variant='body1'>■ 名目帳戶：總資金</Typography>
                    <Typography variant='body1'>■ 帳戶%：名目帳戶百分之多少當作上漲或下跌1個單位的價值。</Typography>
                    <Typography variant='body1'>■ ATR：20日平均真實範圍（Average True Range），用海龜的ATR公式，不用現在的公式。</Typography>

                </Stack>
            </Box>
        </Box>
    );
}
