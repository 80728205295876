import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart, BarChart, CandlestickChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    MarkAreaComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { blue, green, orange, red } from '@mui/material/colors';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Avatar } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import ButtonGroup from '@mui/material/ButtonGroup';

echarts.use(
    [VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, TitleComponent, TooltipComponent, GridComponent, LineChart, CandlestickChart, CanvasRenderer, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent, MarkAreaComponent, BarChart]
);


const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        // marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        // width: `calc(100% + ${6 + (theme.spacing(1))}px)`
    }
}));



export default function TranDetail(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [option, setOption] = React.useState(null);
    const [option1, setOption1] = React.useState(null);
    const [option2, setOption2] = React.useState(null);

    const [result, setResult] = React.useState(null);
    const [open, setOpen] = React.useState(false);

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
    };

    useEffect(() => {
        if (props.open) setOpen(true)
        else setOpen(false)
    }, [props.open]);

    const getStatusString = (status) => {
        if (status === 0) return "進場(1)"
        else if (status === 1) return "加碼(2)"
        else if (status === 2) return "加碼(3)"
        else if (status === 3) return "加碼(4)"
        else if (status === 4) return "停損出場"
        else if (status === 5) return "獲利出場"
        else return ""
    }

    function genoption2(vv, title) {
        let k = {
            animation: false,
            tooltip: {
                show: true
            },
            legend: {
                show: true
            },
            grid: [{
                top: 60,
                left: 0,
                right: 10,
                bottom: 0,
                containLabel: true
            }],
            axisPointer: {
                show: true
            },
            xAxis: [{
                type: 'category',
                boundaryGap: false,
                data: vv.map(row => row.x),
            }],
            yAxis: [{
                scale: true,
                type: 'value',
                position: 'left',
                show: true,
                axisLabel: {
                    inside: true,
                    margin: 0,
                    fontWeight: "bold",
                    fontSize: 12
                },
            }],
            dataZoom: [
                {
                    type: 'inside',
                    start: fullScreen ? 75 : 0,
                    end: 100
                }
            ],
            series: [
                {
                    name: title,
                    type: 'candlestick',
                    data: vv.map(v => { return v.y }),
                    itemStyle: {
                        color: "#d41c1c",
                        color0: '#65b206',
                        borderColor: "#d41c1c",
                        borderColor0: '#65b206'
                    },
                    selectedMode: 'single',
                    markPoint: {
                        label: {
                            show: true,
                            fontSize: 12
                        },
                        data: props.row.trans.map(row => ({
                            value: getStatusString(row.tp),
                            coord: [row.d, row.p],
                            symbol: "triangle",//"triangle",
                            symbolSize: 16,
                            symbolRotate: row.tp === 0 || row.tp === 1 || row.tp === 2 || row.tp === 3 || row.tp === 4 ? 180 : 0,
                            symbolOffset: row.tp === 0 || row.tp === 1 || row.tp === 2 || row.tp === 3 || row.tp === 4 ? [0, -15] : [0, 15],
                            itemStyle: {
                                color: orange[700]
                            },
                            label: {
                                position: row.tp === 0 || row.tp === 1 || row.tp === 2 || row.tp === 3 || row.tp === 4 ? "top" : "bottom",
                            },
                        }))
                    },
                    markLine: {
                        symbol: "none",
                        data: [{
                            yAxis: props.row.al.a1,
                            name: '加碼1',
                            label: {
                                show: true,
                                position: "middle",
                                formatter: '{c}',
                                fontSize: 12,
                                distance: [0, -8]
                            },
                            lineStyle: {
                                color: red[500],
                                width: 2,
                                type: "solid"
                            }
                        },
                        {
                            yAxis: props.row.al.a2,
                            name: '加碼2',
                            label: {
                                show: true,
                                position: "middle",
                                formatter: '{c}',
                                fontSize: 12,
                                distance: [0, -8]
                            },
                            lineStyle: {
                                color: red[500],
                                width: 2,
                                type: "solid"
                            }
                        }, {
                            yAxis: props.row.al.a3,
                            name: '加碼3',
                            label: {
                                show: true,
                                position: "middle",
                                formatter: '{c}',
                                fontSize: 12,
                                distance: [0, -8]
                            },
                            lineStyle: {
                                color: red[500],
                                width: 2,
                                type: "solid"
                            }
                        }, {
                            yAxis: props.row.al.l,
                            name: '停損',
                            label: {
                                show: true,
                                position: "middle",
                                formatter: '{b}: {c}',
                                fontSize: 12
                            },
                            lineStyle: {
                                color: green[500],
                                type: "solid",
                                width: 5
                            }
                        }
                        ]
                    }
                }, {
                    name: 'MA5',
                    type: 'line',
                    data: vv.map(v => { return v.a1 }),
                    showSymbol: false,
                }, {
                    name: 'MA10',
                    type: 'line',
                    data: vv.map(v => { return v.a2 }),
                    showSymbol: false,
                }, {
                    name: 'MA20',
                    type: 'line',
                    data: vv.map(v => { return v.a3 }),
                    showSymbol: false,
                }
            ]
        };

        let av1i = localStorage.getItem('av1i') === null ? true : localStorage.getItem('av1i').toLowerCase() === 'true'
        let av2i = localStorage.getItem('av2i') === null ? true : localStorage.getItem('av2i').toLowerCase() === 'true'
        let av3i = localStorage.getItem('av3i') === null ? true : localStorage.getItem('av3i').toLowerCase() === 'true'

        let selectedo = {}
        selectedo["MA5"] = av1i
        selectedo["MA10"] = av2i
        selectedo["MA20"] = av3i
        k['legend']['selected'] = selectedo

        return k;
    }

    const onChartLegendselectchanged = (event) => {
        let av1v = event.selected['MA5']
        let av2v = event.selected['MA10']
        let av3v = event.selected['MA20']

        if (av1v != null && av1v) {
            localStorage.setItem('av1i', true)
        } else localStorage.setItem('av1i', false)

        if (av2v != null && av2v) {
            localStorage.setItem('av2i', true)
        } else localStorage.setItem('av2i', false)

        if (av3v != null && av3v) {
            localStorage.setItem('av3i', true)
        } else localStorage.setItem('av3i', false)



    }

    const onEvents = {
        'legendselectchanged': onChartLegendselectchanged
    }

    const fetchData = () => {
        let param = {
            sid: props.row.company.stock_no,
        }

        if (props.row.trans != null && props.row.trans.length === 1) {
            param['start'] = props.row.trans[0].d
        } else {
            if (props.row.trans != null && props.row.trans.length > 0) {
                param['end'] = props.row.trans[0].d
            }
            if (props.row.trans != null && props.row.trans.length > 1) {
                param['start'] = props.row.trans[props.row.trans.length - 1].d
            }
        }



        if (localStorage.getItem("ml_lic") != null) {
            param['lic'] = localStorage.getItem("ml_lic")
        }

        fetch("/ml/v1/info/get", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null) {
                        setResult(result)
                    }
                }
            );
    }


    useEffect(() => {
        if (props.row != null) fetchData()
    }, []);

    useEffect(() => {
        if (result != null) {
            setOption(genoption2(result.v1, "日K"))
        }
    }, [result]);

    const getColor = () => {
        if (result != null && result.price != null) {
            if (result.price.i > 0) return "red"
            else if (result.price.i < 0) return "green"
            else return null
        } else return null
    }

    const getInv = () => {
        if (result != null && result.price != null) {
            if (result.price.i > 0) return "▲" + result.price.i + '元'
            else if (result.price.i < 0) return "▼" + result.price.i + '元'
            else return result.price.i + '元'
        } else if (result.price != null)
            return result.price.i + '元'
        else return '▲0元'
    }

    const getStatusColor = (status) => {
        if (status === 4) return "success"
        else if (status === 5) return "error"
        else return "inherit"
    }

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
        >
            <DialogTitle sx={{ p: 0 }}>
            </DialogTitle>
            <DialogContent sx={{ pl: 1, pr: 1 }}>
                {result != null && <Box display={'flex'}>
                    <Stack direction='row' spacing={0.5} sx={{ flex: 1, p: 0, pl: 0, pt: 0.5, alignSelf: 'center' }} >
                        <Typography sx={{ fontSize: 30, fontWeight: 'bold', alignSelf: 'center' }} >{result.company.short_name}</Typography>
                        <Typography variant="caption" sx={{ fontWeight: 'normal' }} >{result.company.stock_no}</Typography>
                    </Stack>
                    <Stack direction='row' spacing={0.5} sx={{ pr: 1, alignSelf: 'center' }} >
                        <Typography color={getColor()} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }}>{result.price != null ? result.price.p : "-"}</Typography>
                        <Typography color={getColor()} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }}>{getInv()}</Typography>
                    </Stack>
                </Box>}

                <Divider sx={{ bgcolor: (theme) => theme.palette.mode === "light" ? "#c1b5c7" : theme.palette.secondary.main }}
                    style={{
                        border: "none",
                        height: 0.5,
                        margin: 0,
                    }} />

                {option != null ?
                    <ReactEChartsCore
                        theme={theme.palette.mode}
                        echarts={echarts}
                        option={option}
                        notMerge={true}
                        lazyUpdate={false}
                        onEvents={onEvents}
                        style={{ height: '60vh', marginTop: 20 }}
                    /> : null}


                {props.row != null && props.row.earn != null && <Box display={"flex"} sx={{ mt: 5 }} >
                    <strong>報酬：</strong><Box sx={{ color: props.row.earn.earn < 0 ? green[500] : red[500] }}>{props.row.earn.earn}元 ({props.row.earn.roi}%)</Box>，<strong>成本：</strong>{props.row.earn.avg}元
                </Box>}


                <Grid container spacing={2}>
                    <Grid xs={12} md={4}>
                        {props.row != null && <TableContainer  component={Paper}>
                            <Table sx={{}} size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ whiteSpace: "nowrap", backgroundColor: theme.palette.common.black, color: theme.palette.common.white }} align="center">名稱</TableCell>
                                        <TableCell sx={{ whiteSpace: "nowrap", backgroundColor: theme.palette.common.black, color: theme.palette.common.white }} align="center">值</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow
                                        key={"1"}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell sx={{ whiteSpace: "nowrap" }} align="center">
                                            加碼價
                                        </TableCell>
                                        <TableCell sx={{ whiteSpace: "nowrap" }} align="center">
                                            {props.row.al != null && <ButtonGroup fullWidth disableElevation variant="text" color='error'  >
                                                <Button>{props.row.al.a1}元</Button>
                                                <Button>{props.row.al.a2}元</Button>
                                                <Button>{props.row.al.a3}元</Button>
                                            </ButtonGroup>}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow
                                        key={"2"}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell sx={{ whiteSpace: "nowrap" }} align="center">
                                            出場價
                                        </TableCell>
                                        <TableCell sx={{ whiteSpace: "nowrap" }} align="center">
                                            {props.row.earn != null && <ButtonGroup fullWidth disableElevation variant="text" color='success'  >
                                                <Button>{props.row.earn.out_price}元</Button>
                                            </ButtonGroup>}

                                        </TableCell>

                                    </TableRow>
                                    <TableRow
                                        key={"3"}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell sx={{ whiteSpace: "nowrap" }} align="center">
                                            停損價
                                        </TableCell>
                                        <TableCell sx={{ whiteSpace: "nowrap" }} align="center">
                                            {props.row.al != null && <ButtonGroup fullWidth disableElevation variant="text" color='success'  >
                                                <Button>{props.row.al.l}元</Button>
                                            </ButtonGroup>}

                                        </TableCell>

                                    </TableRow>

                                </TableBody>
                            </Table>
                        </TableContainer>}
                    </Grid>
                    <Grid xs={12} md={8}>

                        {props.row != null && props.row.trans != null && <TableContainer component={Paper}>
                            <Table sx={{}}  size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ whiteSpace: "nowrap", backgroundColor: theme.palette.common.black, color: theme.palette.common.white }} align="center">狀態</TableCell>
                                        <TableCell sx={{ whiteSpace: "nowrap", backgroundColor: theme.palette.common.black, color: theme.palette.common.white }} >日期</TableCell>
                                        <TableCell sx={{ whiteSpace: "nowrap", backgroundColor: theme.palette.common.black, color: theme.palette.common.white }} align="center">買賣</TableCell>
                                        <TableCell sx={{ whiteSpace: "nowrap", backgroundColor: theme.palette.common.black, color: theme.palette.common.white }} align="right">價格</TableCell>
                                        <TableCell sx={{ whiteSpace: "nowrap", backgroundColor: theme.palette.common.black, color: theme.palette.common.white }} align="right">數量</TableCell>
                                        <TableCell sx={{ whiteSpace: "nowrap", backgroundColor: theme.palette.common.black, color: theme.palette.common.white }} align="right">庫存</TableCell>
                                        <TableCell sx={{ whiteSpace: "nowrap", backgroundColor: theme.palette.common.black, color: theme.palette.common.white }} align="right">停損價</TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {props.row.trans.map((row) => (
                                        <TableRow
                                            key={row.d}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell sx={{ whiteSpace: "nowrap" }} align="center">
                                                <Button disableElevation variant={"contained"} fullWidth color={getStatusColor(row.tp)}>
                                                    {getStatusString(row.tp)}
                                                </Button>
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: "nowrap" }} component="th" scope="row">
                                                {row.d}
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: "nowrap" }} align="center">
                                                <Button fullWidth disableElevation variant="text" color={row.bs === 0 ? "error" : "success"}>{row.bs === 0 ? "買" : "賣"}</Button>
                                            </TableCell>
                                            <TableCell sx={{ whiteSpace: "nowrap" }} align="right">{row.p}元</TableCell>
                                            <TableCell sx={{ whiteSpace: "nowrap" }} align="right">{row.n}股</TableCell>
                                            <TableCell sx={{ whiteSpace: "nowrap" }} align="right">{row.storage}股</TableCell>
                                            <TableCell sx={{ whiteSpace: "nowrap" }} align="right">{row.stop_loss}元</TableCell>

                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>}

                    </Grid>

                </Grid>




            </DialogContent>
            <DialogActions>
                <Button disableElevation variant="contained" color="info" onClick={handleClose}>關閉</Button>
            </DialogActions>
        </Dialog>
    );
}

