import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart, BarChart, CandlestickChart } from 'echarts/charts';
import {
    GridComponent,
    TooltipComponent,
    TitleComponent,
    TimelineComponent,
    MarkPointComponent,
    MarkLineComponent,
    MarkAreaComponent,
    LegendComponent,
    DataZoomComponent,
    DataZoomInsideComponent,
    DataZoomSliderComponent,
    VisualMapComponent,
    VisualMapContinuousComponent,
    VisualMapPiecewiseComponent,
} from 'echarts/components';
import {
    CanvasRenderer,
} from 'echarts/renderers';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import { green, grey, red } from '@mui/material/colors';

echarts.use(
    [VisualMapComponent, VisualMapContinuousComponent, VisualMapPiecewiseComponent, TitleComponent, TooltipComponent, GridComponent, LineChart, CandlestickChart, CanvasRenderer, DataZoomComponent,
        DataZoomInsideComponent, DataZoomSliderComponent, MarkPointComponent, MarkLineComponent, TimelineComponent,
        LegendComponent, MarkAreaComponent, BarChart]
);


const useStyles = makeStyles(theme => ({
    table: {
        // minWidth: 650,
    },
    root1: {
        flexGrow: 1,
    },
    menuButton: {
        // marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    grid: {
        // width: `calc(100% + ${6 + (theme.spacing(1))}px)`
    }
}));



export default function HistoryChart(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [option, setOption] = React.useState(null);
    const [option2, setOption2] = React.useState(null);

    const [result, setResult] = React.useState(null);

    const [open, setOpen] = React.useState(false);

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
    };

    useEffect(() => {
        if (props.open) setOpen(true)
        else setOpen(false)
    }, [props.open]);

    


    function genOption2() {
        let k = {
            animation: false,
            tooltip: {
                show: true
            },
            legend:{
                show : true,
                bottom: 0,
                icon: 'rect'
            },
            grid: [{
                top: 10,
                left: 0,
                right: 20,
                bottom: 40,
                containLabel: true
            }],
            axisPointer: {
                show: true
            },
            xAxis: [{
                type: 'category',
                boundaryGap: false,
                data: result.x_value,
            }],
            yAxis: [{
                scale: true,
                type: 'value',
                position: 'left',
                show: true,
            }],
            dataZoom: {
                type: 'inside',
                xAxisIndex: [0, 1],
                start: 0,
                end: 100
            }

            // series: [

            // ]
        };

        let a = []
        if(result.history != null) {
            result.history.filter (v => v.day == 2 && v.algorithm +"_0" === props.algorithm).map(row => {
                a.push({
                    name: row.day +"日預測",
                    type: 'line',
                    data: row.predictions.map(vv => [vv.x, vv.y]),
                    smooth: false,
                    showSymbol: false,
                    symbolSize: 8,
                    endLabel: {
                        show: false
                    },
                    connectNulls: true
                })
            })
            result.history.filter (v => v.day == 4 && v.algorithm+"_0" === props.algorithm).map(row => {
                a.push({
                    name: row.day +"日預測",
                    type: 'line',
                    data: row.predictions.map(vv => [vv.x, vv.y]),
                    smooth: false,
                    showSymbol: false,
                    symbolSize: 8,
                    endLabel: {
                        show: false
                    },
                    connectNulls: true
                })
            })

            result.history.filter (v => v.day == 6 && v.algorithm+"_0" === props.algorithm).map(row => {
                a.push({
                    name: row.day +"日預測",
                    type: 'line',
                    data: row.predictions.map(vv => [vv.x, vv.y]),
                    smooth: false,
                    showSymbol: false,
                    symbolSize: 8,
                    endLabel: {
                        show: false
                    },
                    connectNulls: true
                })
            })

        }
        a.push({
            name: "股價",
            type: 'candlestick',
            data: result.history_prices.reverse().map(vv => [vv.o, vv.p, vv.l, vv.h]),
            smooth: false,
            showSymbol: false,
            symbolSize: 8,
            endLabel: {
                show: false
            },
            connectNulls: true
        })

        // a.push({
        //     name: "股價",
        //     type: 'line',
        //     data: result.history_prices.map(row => [row.x, row.y]),
        //     smooth: false,
        //     showSymbol: false,
        //     symbolSize: 8,
        //     endLabel: {
        //         show: false
        //     },
        // })
        // a.push(series)
        k["series"] = a
        return k;
    }


    const fetchData = () => {
        let param = {
            sid: props.sid,
            algorithm: props.algorithm
        }

        if (localStorage.getItem("ml_lic") != null) {
            param['lic'] = localStorage.getItem("ml_lic")
        }

        fetch("/ml/v1/history/get2", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null) {
                        setResult(result)
                    }
                }
            );
    }


    useEffect(() => {
        if (props.sid != null) fetchData()
    }, []);

    useEffect(() => {
        if (result != null) {
            setOption2(genOption2)
        }
    }, [result]);

    const getColor = () => {
        if (result != null && result.price != null) {
            if (result.price.i > 0) return "red"
            else if (result.price.i < 0) return "green"
            else return null
        } else return null
    }

    const getInv = () => {
        if (result != null && result.price != null) {
            if (result.price.i > 0) return "▲" + result.price.i + '元'
            else if (result.price.i < 0) return "▼" + result.price.i + '元'
            else return result.price.i + '元'
        } else if (result.price != null)
            return result.price.i + '元'
        else return '▲0元'
    }

    const getInv1 = (row, sv) => {
        if (row != null && row.price != null) {
            let pp = (((sv - row.price.p) * 100) / row.price.p).toFixed(1)
            let k = (sv - row.price.p).toFixed(2)

            if (k > 0) return "▲" + k + ' (' + pp + '%)'
            else if (k < 0) return "▼" + k + ' (' + pp + '%)'
            else return k + '元' + ' (' + pp + '%)'
        } else return '▲0元'
    }

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
        >
            <DialogTitle sx={{ p: 0 }}>
            </DialogTitle>
            <DialogContent sx={{ pl: 1, pr: 1 }}>
                {result != null && <Box display={'flex'}>
                    <Stack direction='row' spacing={0.5} sx={{ flex: 1, p: 0, pl: 0, pt: 0.5, alignSelf: 'center' }} >
                        <Typography sx={{ fontSize: 30, fontWeight: 'bold', alignSelf: 'center' }} >{result.company.short_name}</Typography>
                        <Typography variant="caption" sx={{ fontWeight: 'normal' }} >{result.company.stock_no}</Typography>
                    </Stack>
                    <Stack direction='row' spacing={0.5} sx={{ pr: 1, alignSelf: 'center' }} >
                        <Typography color={getColor()} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }}>{result.price != null ? result.price.p : "-"}</Typography>
                        <Typography color={getColor()} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }}>{getInv()}</Typography>
                    </Stack>
                </Box>}

                <Divider sx={{ bgcolor: (theme) => theme.palette.mode === "light" ? "#c1b5c7" : theme.palette.secondary.main }}
                    style={{
                        border: "none",
                        height: 0.5,
                        margin: 0,
                    }} />

                <Stack direction={"row"} spacing={1} sx={{mt:1.5}}>
                    <Typography fontSize={28}>{props.algorithm}機器學習</Typography>
                    {/* {result != null && <Typography sx={{ alignSelf: "center" }} fontSize={16}>整體上漲率：{result.up_rate}%</Typography>} */}
                </Stack>

                <Grid container spacing={2} sx={{ mt: 0 }}>
                    <Grid xs={12}>
                        {option2 != null ?
                            <ReactEChartsCore
                                theme={theme.palette.mode}
                                echarts={echarts}
                                option={option2}
                                notMerge={true}
                                lazyUpdate={false}
                                style={{ height: '50vh' }}
                            />
                            : null}
                    </Grid>
                </Grid>



            </DialogContent>
            <DialogActions>
                <Button disableElevation variant="contained" color="inherit" fullWidth onClick={handleClose}>關閉</Button>
            </DialogActions>
        </Dialog>
    );
}

