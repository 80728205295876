import React, { useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/system';
import { Button, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import { CheckBox, Delete } from '@mui/icons-material';
import Stack from '@mui/material/Stack';
import { purple, deepOrange, green, red, blueGrey, blue, pink, grey } from '@mui/material/colors';
import Avatar from '@mui/material/Avatar';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { getId, numberWithCommas } from '../util/ID'
import { putInpQueryParam } from '../util/ID'
import { useTheme } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Height } from '../util/ScreenHeight'
import BuyDialog from './BuyDialog'
import AlertD from '../util/AlertD'
import RuleDialog1 from './RuleDialog1'
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import DetailKChart from '../rec/DetailKChart'
import StockHistoryDialog from './StockHistoryDialog';
import StrageDialog from '../strage/StrageDialog';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Unstable_Grid2';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ListSubheader from '@mui/material/ListSubheader';
import SearchCompany from '../love/SearchCompany';
import DetailChart from '../rec/DetailChart';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        // fontWeight:"bold",
        fontSize: 15,
        whiteSpace: "nowrap",
        // backgroundColor: grey[600],
        // color: theme.palette.common.white,
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,

    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 15,
        whiteSpace: "nowrap",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // '&:nth-of-type(odd)': {
    //     backgroundColor: theme.palette.action.hover,
    // },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


export default function LoveTable(props) {
    const [page, setPage] = React.useState(1);
    const [total, setTotal] = React.useState(1)
    const [result, setResult] = React.useState(null);
    const [loading, setLoading] = React.useState(false)

    const [buyNum, setBuyNum] = React.useState(0)

    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);
    const [openSetting, setOpenSetting] = React.useState(false);
    const [openSetting1, setOpenSetting1] = React.useState(false);
    const [openSetting2, setOpenSetting2] = React.useState(false);
    const [openSetting3, setOpenSetting3] = React.useState(false);
    const [openSetting4, setOpenSetting4] = React.useState(false);
    const [openSetting5, setOpenSetting5] = React.useState(false);
    const [system, setSystem] = React.useState("OLS");
    const [day, setDay] = React.useState("0");
    const [dir, setDir] = React.useState("0");
    const [sid, setSid] = React.useState(null);
    const [row, setRow] = React.useState(null);
    const [age, setAge] = React.useState("");

    const [oneSid, setOneSid] = React.useState(null);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const openDetail2 = (id) => {
        setSid(id)
        setOpenSetting2(true)
    }

    const openDetail5 = (id) => {
        setSid(id)
        setOpenSetting5(true)
    }

    const handlePageChange = (event, value) => {
        setPage(value);
        fetchDataInter(value)
    };

    const fetchInitData = () => {
        setPage(1)
        fetchDataInter(1)
    }

    const fetchDataInter = (pv) => {
        setLoading(true)

        let capital = localStorage.getItem("ml_capital") != null ? localStorage.getItem("ml_capital") : 1000000
        let risk = localStorage.getItem("ml_risk") != null ? localStorage.getItem("ml_risk") : 1
        let atr = localStorage.getItem("ml_atr") != null ? localStorage.getItem("ml_atr") : 20
        let rules = localStorage.getItem("ml_search_rules" + dir) != null ? JSON.parse(localStorage.getItem("ml_search_rules" + dir)) : []

        let param = {
            sn: getId(),
            rules: rules,
            inp: {}
        }

        if (localStorage.getItem("ml_lic") != null) {
            param['lic'] = localStorage.getItem("ml_lic")
        }

        param = putInpQueryParam(param)

        fetch("/ml/v1/love/get", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    setLoading(false)

                    if (result != null) {
                        if (result.data != null && result.data.length > 0) {
                            setTotal(result.total)
                            setResult(result.data)
                        } else {
                            setTotal(0)
                            setResult([])
                        }
                    }
                }
            );
    }

    useEffect(() => {
        fetchInitData()
    }, [system, dir, day, oneSid]);

    const getColor = (row) => {
        if (row != null && row.price != null) {
            if (row.price.i > 0) return red[400]
            else if (row.price.i < 0) return green[400]
            else return null
        } else return null
    }

    const getInv = (row) => {
        if (row != null && row.price != null) {
            let pp = ((row.price.i * 100) / (row.price.p - row.price.i)).toFixed(1)

            if (row.price.i > 0) return "▲" + row.price.i + ' (' + pp + '%)'
            else if (row.price.i < 0) return "▼" + row.price.i + ' (' + pp + '%)'
            else return row.price.i + '元' + ' (' + pp + '%)'
        } else return '▲0元'
    }


    const clickLove = (row) => {
        setLoading(true)

        let param = {
            sn: getId(),
            sid: row.company.stock_no,
        }

        if (localStorage.getItem("ml_lic") != null) {
            param['lic'] = localStorage.getItem("ml_lic")
        }

        fetch("/ml/v1/love/click", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    setLoading(false)
                    // fetchInitData()
                    if (result != null && result.status === 0) {
                        fetchInitData()
                        // setAlertMsg("刪除成功")
                        // setShowAlert(true)
                    }
                }
            );
    }


    const handleChange = (event) => {
        setSystem(event.target.value);
    };


    const handleDirChange = (event) => {
        setDir(event.target.value);
    };

    const convertPiece = (v) => {
        if (v >= 1000) return (v / 1000).toFixed(1) + "張"
        else return v + "股"
    }


    const getWord = (row, i) => {
        if (row.pr != null && row.pr.p != null && row.pr.p.length > i) return row.pr.p[i] + "元"
        else return "-"
    }

    const getColor1 = (row, i) => {
        if (row.price != null) {
            if (row.pr != null && row.pr.p != null && row.pr.p.length > i && row.pr.p[i] > row.price.p) return red[400]
            else if (row.pr != null && row.pr.p != null && row.pr.p.length > i && row.pr.p[i] < row.price.p) return green[600]
            return grey[400]
        } else return grey[400]
    }

    const getInv1 = (row, i) => {
        if (row != null && row.price != null && row.pr != null && row.pr.p != null && row.pr.p.length > i) {
            let pp = (((row.pr.p[i] - row.price.p) * 100) / row.price.p).toFixed(1)
            let k = (row.pr.p[i] - row.price.p).toFixed(2)

            if (k > 0) return "▲" + k + ' (' + pp + '%)'
            else if (k < 0) return "▼" + k + ' (' + pp + '%)'
            else return k + '元' + ' (' + pp + '%)'
        } else return '▲0元'
    }


    const getColor2 = (row, i) => {

        if (row.pr != null && row.pr.p1 != null && row.pr.p1.length > i && row.pr.p1[i].w > 70) return red[400]
        else if (row.pr != null && row.pr.p1 != null && row.pr.p1.length > i && row.pr.p1[i].w < 30) return green[600]
        return grey[600]
    }
    const getColor3 = (row, i) => {
        return grey[600]
    }

    const getString2 = (row, i) => {
        if (row.pr != null && row.pr.p1 != null && row.pr.p1.length > i) return row.pr.p1[i].n + " / " + row.pr.p1[i].total + ""
        return "-"
    }


    return (
        <>
            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}
            {openSetting1 ? <RuleDialog1 dir={dir} refresh={fetchInitData} open={openSetting1} close={setOpenSetting1}></RuleDialog1> : null}
            {openSetting2 && <DetailKChart sid={sid} open={openSetting2} close={setOpenSetting2} />}
            {openSetting3 && <StockHistoryDialog sid={sid} open={openSetting3} close={setOpenSetting3} />}
            {openSetting4 && <StrageDialog open={openSetting4} close={setOpenSetting4} refresh={fetchInitData} />}
            {openSetting5 && <DetailChart open={openSetting5} close={setOpenSetting5} sid={sid} />}

            {/* <Box sx={{width: "100%", overflow:"scroll"}}> */}
            {/* <Box sx={{position: 'absolute', top: Height.header, bottom: props.ismobile || fullScreen ? 100 : 55, overflowY: 'hidden', overflowX: 'hidden'}}> */}
            <Box sx={{ p: 0, width: "100%", overflow: 'hidden' }}>

                <TableContainer sx={{ position: 'absolute', top: 20+ Height.header, bottom: props.ismobile || fullScreen ? 100 : 55, }} >
                    <Table sx={{}} stickyHeader size="small">
                        <TableHead>
                            <TableRow >
                                <StyledTableCell sx={{ zIndex: 999, position: "sticky", left: 0 }}>股票</StyledTableCell>
                                <StyledTableCell >類股</StyledTableCell>
                                <StyledTableCell align="center">策略名稱</StyledTableCell>
                                <StyledTableCell align="center">2日後預測</StyledTableCell>
                                <StyledTableCell align="center">4日後預測</StyledTableCell>
                                <StyledTableCell align="center">6日後預測</StyledTableCell>
                                
                                <StyledTableCell align="center">2日看多率</StyledTableCell>
                                <StyledTableCell align="center">4日看多率</StyledTableCell>
                                <StyledTableCell align="center">6日看多率</StyledTableCell>
                                <StyledTableCell align="center">動作</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {result != null && result.map((row) => (
                                <StyledTableRow key={row.company.stock_no}>
                                    <StyledTableCell sx={{zIndex: 998, position: "sticky", left: 0, bgcolor: theme.palette.mode === "light" ? grey[50] : grey[900], color: theme.palette.mode === "light" ? "#000000" : "#FFFFFF" }}  onClick={e => openDetail2(row.company.stock_no)} >
                                        {row.company.short_name} {row.company.stock_no}

                                        <Stack direction='row' spacing={0.5} sx={{ pr: 1, alignSelf: 'center' }} >
                                            <Typography color={getColor(row)} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }} >{row.price != null ? row.price.p : "-"}</Typography>
                                            <Typography color={getColor(row)} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }} >{getInv(row)}</Typography>
                                        </Stack>
                                    </StyledTableCell>
                                    <StyledTableCell sx={{}} onClick={e => openDetail2(row.company.stock_no)} >
                                        {row.company.inductry}
                                    </StyledTableCell>
                                    <StyledTableCell align='center' >
                                        {row.love.algorithm}
                                    </StyledTableCell>

                                    <StyledTableCell align="right" onClick={e => openDetail5(row.company.stock_no)}>
                                        <Stack direction='column' spacing={0.5} sx={{ pr: 1, alignSelf: 'right' }} >
                                            <Typography color={getColor1(row, 0)} fontSize={16} sx={{ fontWeight: 'normal', alignSelf: 'right' }} >{getWord(row, 0)}</Typography>
                                            <Typography color={getColor1(row, 0)} fontSize={12} sx={{ fontWeight: 'normal', alignSelf: 'right' }} >{getInv1(row, 0)}</Typography>
                                        </Stack>
                                    </StyledTableCell>
                                    <StyledTableCell align="right" onClick={e => openDetail5(row.company.stock_no)}>
                                        <Stack direction='column' spacing={0.5} sx={{ pr: 1, alignSelf: 'right' }} >
                                            <Typography color={getColor1(row, 1)} fontSize={16} sx={{ fontWeight: 'normal', alignSelf: 'right' }} >{getWord(row, 1)}</Typography>
                                            <Typography color={getColor1(row, 1)} fontSize={12} sx={{ fontWeight: 'normal', alignSelf: 'right' }} >{getInv1(row, 1)}</Typography>
                                        </Stack>
                                    </StyledTableCell>
                                    <StyledTableCell align="right" onClick={e => openDetail5(row.company.stock_no)}>
                                        <Stack direction='column' spacing={0.5} sx={{ pr: 1, alignSelf: 'right' }} >
                                            <Typography color={getColor1(row, 2)} fontSize={16} sx={{ fontWeight: 'normal', alignSelf: 'right' }} >{getWord(row, 2)}</Typography>
                                            <Typography color={getColor1(row, 2)} fontSize={12} sx={{ fontWeight: 'normal', alignSelf: 'right' }} >{getInv1(row, 2)}</Typography>
                                        </Stack>
                                    </StyledTableCell>


                                    
                                    <StyledTableCell align="right" onClick={e => openDetail5(row.company.stock_no)}>
                                        <Stack direction='column' spacing={0.5} sx={{ pr: 1, alignSelf: 'right' }} >
                                        <Typography color={getColor3(row, 0)} fontSize={16} sx={{ fontWeight: 'normal', alignSelf: 'right' }} >{row.pr != null && row.pr.p1 != null ? row.pr.p1[0].w : "-"}%</Typography>
                                            <Typography color={getColor3(row, 0)} fontSize={12} sx={{ fontWeight: 'normal', alignSelf: 'right' }} >{getString2(row, 0)}</Typography>

                                        </Stack>
                                    </StyledTableCell>

                                    <StyledTableCell align="right" onClick={e => openDetail5(row.company.stock_no)}>
                                        <Stack direction='column' spacing={0.5} sx={{ pr: 1, alignSelf: 'right' }} >
                                        <Typography color={getColor3(row, 1)} fontSize={16} sx={{ fontWeight: 'normal', alignSelf: 'right' }} >{row.pr != null && row.pr.p1 != null ? row.pr.p1[1].w : "-"}%</Typography>
                                            <Typography color={getColor3(row, 1)} fontSize={12} sx={{ fontWeight: 'normal', alignSelf: 'right' }} >{getString2(row, 1)}</Typography>
                                        </Stack>
                                    </StyledTableCell>

                                    <StyledTableCell align="right" onClick={e => openDetail5(row.company.stock_no)}>
                                        <Stack direction='column' spacing={0.5} sx={{ pr: 1, alignSelf: 'right' }} >
                                        <Typography color={getColor3(row, 2)} fontSize={16} sx={{ fontWeight: 'normal', alignSelf: 'right' }} >{row.pr != null && row.pr.p1 != null ? row.pr.p1[2].w : "-"}%</Typography>
                                            <Typography color={getColor3(row, 2)} fontSize={12} sx={{ fontWeight: 'normal', alignSelf: 'right' }} >{getString2(row, 2)}</Typography>
                                        </Stack>
                                    </StyledTableCell>
                                   
                                    <StyledTableCell align="center">
                                        <Button color="inherit" startIcon={<Delete />} onClick={e => clickLove(row)} disableElevation variant='contained' sx={{
                                        }}>刪除</Button>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            {result != null && result.length > 0 && < Box style={{ flexShrink: 0 }} sx={{ position: 'absolute', bottom: props.ismobile || fullScreen ? 60 : 0, width: '100%', mt: 0.5, mb: 0.5, display: 'flex', justifyContent: "center" }}>
                <Pagination page={page} size="small" siblingCount={1} boundaryCount={1} count={total} variant="outlined" shape="rounded" onChange={handlePageChange} />
            </Box>}
        </>
    );
}
